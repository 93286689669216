import React from "react";
import { Link } from "react-router-dom";
import { FiChevronsRight } from "react-icons/fi";

const Breadcrumb = (props) => (
	<section className="breadcrumb__area breadcrumb-style pt-90 pb-100 p-relative z-index-1">
		<div className="breadcrumb__bg-overlay m-img"></div>
		<div className="container">
			<div className="row justify-content-center">
				<div className="col-xl-10">
					<div className="breadcrumb__content text-center">
						<h3 className="breadcrumb__title">{props.title}</h3>
						<div className="breadcrumb__list breadcrumb__list-translate">
							<span>
								<Link to="/home">{"Home"}</Link>
							</span>
							<span className="dvdr">
								<FiChevronsRight size={20} />
							</span>
							<span>{props.currentPageName}</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
);
export default Breadcrumb;
