import React, { Fragment, useState, useEffect } from "react";
import { FiArrowUpRight } from "react-icons/fi";
import Breadcrumb from "../components/Breadcrumb";
import ServiceOneModal from "../components/service/ServiceOneModal";
import ServiceTwoModal from "../components/service/ServiceTwoModal";
import ServiceThreeModal from "../components/service/ServiceThreeModal";
import ServiceFourModal from "../components/service/ServiceFourModal";

import monitoringSvg from "../assets/img/svg/monitoring.svg";
import memorySvg from "../assets/img/svg/memory.svg";
import encryptedSvg from "../assets/img/svg/encrypted.svg";
import backupSvg from "../assets/img/svg/backup.svg";

const Services = () => {
	document.title = "Services";
	const [isServiceOneModalOpen, toggleServiceOneModal] = useState(false);
	const [isServiceTwoModalOpen, toggleServiceTwoModal] = useState(false);
	const [isServiceThreeModalOpen, toggleServiceThreeModal] = useState(false);
	const [isServiceFourModalOpen, toggleServiceFourModal] = useState(false);

	useEffect(() => {
		window.AOS.init();
	}, []);

	const onOpenServiceOneModal = () => {
		toggleServiceOneModal(true);
	};

	const onOpenServiceTwoModal = () => {
		toggleServiceTwoModal(true);
	};

	const onOpenServiceThreeModal = () => {
		toggleServiceThreeModal(true);
	};

	const onOpenServiceFourModal = () => {
		toggleServiceFourModal(true);
	};

	const onHideServiceOneModal = () => {
		toggleServiceOneModal(false);
	};

	const onHideServiceTwoModal = () => {
		toggleServiceTwoModal(false);
	};

	const onHideServiceThreeModal = () => {
		toggleServiceThreeModal(false);
	};

	const onHideServiceFourModal = () => {
		toggleServiceFourModal(false);
	};

	return (
		<Fragment>
			<Breadcrumb title="Our Services" currentPageName="Services" />
			<section className="cr-service-breadcrumb-area p-relative pt-60 pb-60">
				<div className="container">
					<div
						className="row align-items-center"
						data-aos="fade-left"
						data-aos-duration="1500"
					>
						<div className="col-lg-12">
							<div className="cr-service-breadcrumb-title-wrapper">
								<h3 className="cr-section-title">
									{"Services We "}
									<span className="title-color">{"Offer"}</span>
								</h3>
							</div>
						</div>
					</div>
					<div className="row" data-aos="fade-down" data-aos-duration="2000">
						<div className="col-xs-12 col-sm-6 col-md-3">
							<div className="cr-service-3-content breadcrumb-item mb-30">
								<div className="text-center mb-4">
									<img src={monitoringSvg} alt="" className="img-fluid" />
								</div>
								<h4 className="cr-service-breadcrumb-title">
									{"Analytic & "}
									<br />
									{"Engineering"}
								</h4>
								<p>Lorem ipsum odor amet, consectetuer adipiscing elit.</p>
								<div className="cr-service-btn" onClick={onOpenServiceOneModal}>
									{"Read More"}
									<FiArrowUpRight size={20} />
								</div>
							</div>
						</div>
						<div className="col-xs-12 col-sm-6 col-md-3">
							<div className="cr-service-3-content breadcrumb-item mb-30">
								<div className="text-center mb-4">
									<img src={memorySvg} alt="" className="img-fluid" />
								</div>
								<h4 className="cr-service-breadcrumb-title">
									{"Machine Learning"}
									<br />
									{"& AI"}
								</h4>
								<p>Lorem ipsum odor amet, consectetuer adipiscing elit.</p>
								<div className="cr-service-btn" onClick={onOpenServiceTwoModal}>
									{"Read More"}
									<FiArrowUpRight size={20} />
								</div>
							</div>
						</div>
						<div className="col-xs-12 col-sm-6 col-md-3">
							<div className="cr-service-3-content breadcrumb-item mb-30">
								<div className="text-center mb-4">
									<img src={encryptedSvg} alt="" className="img-fluid" />
								</div>
								<h4 className="cr-service-breadcrumb-title">
									{"IT Server & "}
									<br />
									{"Cyber Security"}
								</h4>
								<p>Lorem ipsum odor amet, consectetuer adipiscing elit.</p>
								<div
									className="cr-service-btn"
									onClick={onOpenServiceThreeModal}
								>
									{"Read More"}
									<FiArrowUpRight size={20} />
								</div>
							</div>
						</div>
						<div className="col-xs-12 col-sm-6 col-md-3">
							<div className="cr-service-3-content breadcrumb-item mb-30">
								<div className="text-center mb-4">
									<img src={backupSvg} alt="" className="img-fluid" />
								</div>
								<h4 className="cr-service-breadcrumb-title">
									{"Data & Cloud "}
									<br />
									{"Computing"}
								</h4>
								<p>Lorem ipsum odor amet, consectetuer adipiscing elit.</p>
								<div
									className="cr-service-btn"
									onClick={onOpenServiceFourModal}
								>
									{"Read More"}
									<FiArrowUpRight size={20} />
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<ServiceOneModal
				show={isServiceOneModalOpen}
				onHide={onHideServiceOneModal}
			/>
			<ServiceTwoModal
				show={isServiceTwoModalOpen}
				onHide={onHideServiceTwoModal}
			/>
			<ServiceThreeModal
				show={isServiceThreeModalOpen}
				onHide={onHideServiceThreeModal}
			/>
			<ServiceFourModal
				show={isServiceFourModalOpen}
				onHide={onHideServiceFourModal}
			/>
		</Fragment>
	);
};

export default Services;
