import React, { useState } from "react";
import { FiCheck } from "react-icons/fi";
import Nav from "react-bootstrap/Nav";

const HowWeDoArea = (props) => {
	const [activeTabKey, setActiveTabKey] = useState("integrity");

	const onSelect = (eventKey, event) => {
		setActiveTabKey(eventKey);
	};

	const renderTabContent = () => {
		switch (activeTabKey) {
			case "integrity":
				return (
					<ul>
						{[1, 2, 3, 4, 5].map((item, index) => (
							<li key={`integrity-${index}`}>
								<div className="svg-box">
									<FiCheck size={20} />
								</div>
								<p className="m-0">
									Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
									do eiusmod tempor incididunt ut labore et dolore magna aliqua.
								</p>
							</li>
						))}
					</ul>
				);
			case "obejectives":
				return (
					<ul>
						{[1, 2, 3, 4, 5].map((item, index) => (
							<li key={`obejectives-${index}`}>
								<div className="svg-box">
									<FiCheck size={20} />
								</div>
								<p className="m-0">
									Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
									do eiusmod tempor incididunt ut labore et dolore magna aliqua.
								</p>
							</li>
						))}
					</ul>
				);
			case "excellence":
				return (
					<ul>
						{[1, 2, 3, 4, 5].map((item, index) => (
							<li key={`excellence-${index}`}>
								<div className="svg-box">
									<FiCheck size={20} />
								</div>
								<p className="m-0">
									Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
									do eiusmod tempor incididunt ut labore et dolore magna aliqua.
								</p>
							</li>
						))}
					</ul>
				);
			default:
				return null;
		}
	};

	return (
		<section className="cr-industry-area p-relative pt-60 pb-60">
			<div className="container">
				<div className="row">
					<div className="col-lg-12">
						<div className="cr-industry-title-wrapper text-center">
							<h3 className="cr-section-title">
								{"How We"}
								<span className="title-color">{" Do It"}</span>
							</h3>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-xl-12 col-lg-12">
						<div
							className="cr-industry-wrapper mb-30"
							data-aos="fade-left"
							data-aos-duration="1500"
						>
							<div className="cr-industry-tab">
								<Nav
									id="pills-tab"
									variant="pills"
									defaultActiveKey={activeTabKey}
									onSelect={onSelect}
								>
									<Nav.Item>
										<Nav.Link eventKey="integrity">integrity</Nav.Link>
									</Nav.Item>
									<Nav.Item>
										<Nav.Link eventKey="obejectives">obejectives</Nav.Link>
									</Nav.Item>
									<Nav.Item>
										<Nav.Link eventKey="excellence">excellence</Nav.Link>
									</Nav.Item>
								</Nav>
								<div className="cr-industry-tab-content">
									{renderTabContent()}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};
export default HowWeDoArea;
