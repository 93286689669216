import React, { Fragment, useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { FiX } from "react-icons/fi";
import {
	HOME_PATH,
	ABOUT_US_PATH,
	SERVICES_PATH,
	CONTACT_US_PATH,
} from "../configs/constant";

const OffCanvasArea = () => {
	const location = useLocation();
	const [pathname, setPathname] = useState(HOME_PATH);

	useEffect(() => {
		const path = location.pathname.replaceAll("/", "");
		setPathname(path.trim().length === 0 ? HOME_PATH : path);
	}, [location]);

	return (
		<Fragment>
			<div className="offcanvas__area home-2-pos">
				<div className="offcanvas__wrapper">
					<div className="offcanvas__close">
						<button className="offcanvas__close-btn offcanvas-close-btn">
							<FiX size={18} />
						</button>
					</div>
					<div className="offcanvas__content">
						<div className="offcanvas__top mb-50 d-flex justify-content-between align-items-center">
							<div className="offcanvas__logo logo">
								<Link to={`/${HOME_PATH}`}>
									<h3>LOGO HERE</h3>
								</Link>
							</div>
						</div>
						<div className="cr-mobile-menu-pos mean-container">
							<nav className="mean-nav">
								<ul>
									<li>
										<Link
											to={`/${HOME_PATH}`}
											className={pathname === HOME_PATH ? "active" : null}
										>
											{"Home"}
										</Link>
									</li>
									<li>
										<Link
											to={`/${ABOUT_US_PATH}`}
											className={pathname === ABOUT_US_PATH ? "active" : null}
										>
											{"About Us"}
										</Link>
									</li>
									<li>
										<Link
											to={`/${SERVICES_PATH}`}
											className={pathname === SERVICES_PATH ? "active" : null}
										>
											{"Services"}
										</Link>
									</li>
									<li>
										<Link
											to={`/${CONTACT_US_PATH}`}
											className={pathname === CONTACT_US_PATH ? "active" : null}
										>
											{"Contact"}
										</Link>
									</li>
								</ul>
							</nav>
						</div>
					</div>
				</div>
			</div>
			<div className="body-overlay"></div>
		</Fragment>
	);
};

export default OffCanvasArea;
