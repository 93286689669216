import React, { Fragment, useEffect, useState, useRef } from "react";
import Alert from "react-bootstrap/Alert";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Breadcrumb from "../components/Breadcrumb";

const validationSchema = Yup.object().shape({
	name: Yup.string()
		.trim()
		.required("Enter your name")
		.matches(
			/^(?=.{1,60}$)[a-z]+(?:['_.\s][a-z]+)*$/i,
			"Should not contain any number"
		),
	email: Yup.string()
		.trim()
		.required("Enter your email")
		.matches(/^[a-z][0-9a-z]/i, "Email should not start with number")
		.matches(/^\S*$/, "Email should not contain any space")
		.matches(
			/^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/,
			"Please enter a valid email"
		),
	contactNo: Yup.string()
		.trim()
		.min(4, "Enter your mobile number")
		.max(13, "Enter your mobile number")
		.matches(/^\d+$/, "Only takes number"),
	serviceName: Yup.string()
		.trim()
		.required("Enter a service name")
		.min(5, "Service name must be contain atleast 5 characters"),
	message: Yup.string()
		.trim()
		.required("Enter your message")
		.min(30, "Message must be contain atleast 30 characters"),
});

const Contact = () => {
	document.title = "Contact";
	const formikRef = useRef(null);
	const [isAlertShow, setAlertShow] = useState(false);
	const [isSubmitting, setSubmitting] = useState(false);

	useEffect(() => {
		window.AOS.init();
	}, []);

	const onChangeEmail = (event) => {
		let value = event.target.value;
		formikRef &&
			formikRef.current.setFieldValue("email", value.trim().toLowerCase());
	};

	const onCLoseAlert = () => {
		setAlertShow(false);
	};

	const onSubmitForm = (values) => {
		setSubmitting(true);
		setTimeout(() => {
			setSubmitting(false);
			formikRef && formikRef.current.resetForm();
			setAlertShow(true);
			setTimeout(() => {
				setAlertShow(false);
			}, 5000);
		}, 1000);
	};

	return (
		<Fragment>
			<Breadcrumb title="Contact Us" currentPageName="Contact" />
			<section className="cr-contact-area pt-80 pb-50">
				<div
					className="container"
					data-aos="fade-left"
					data-aos-duration="2500"
				>
					<div className="row">
						<div className="col-lg-12">
							<div className="cr-contact-wrapper">
								<div className="cr-contact-title-wrapper">
									<h3 className="cr-contact-title">
										Contact <span className="title-color">With us</span>
									</h3>
								</div>
							</div>
						</div>
						<div className="col-lg-12">
							<div className="cr-contact-form p-0">
								<p className="mb-0">
									Do not worry, we will not published your details.
								</p>
								<p>Required fields are marked *</p>

								{isAlertShow ? (
									<Alert variant="success" onClose={onCLoseAlert} dismissible>
										<Alert.Heading>
											{"Thanks for getting in touch!"}
										</Alert.Heading>
										<p>
											We appreciate you contacting us. We will get back in touch
											with you soon. Have a great day.
										</p>
									</Alert>
								) : null}

								<Formik
									initialValues={{
										name: "",
										email: "",
										contactNo: "",
										serviceName: "",
										message: "",
									}}
									validationSchema={validationSchema}
									onSubmit={onSubmitForm}
									innerRef={formikRef}
								>
									{({ errors, touched, values, handleChange }) => (
										<Form autoComplete="off" className="cr-contact-input">
											<div className="row mb-4">
												<div className="col-xs-12 col-md-6 mb-4 mb-sm-0">
													<div className="form-group">
														<input
															name="name"
															type="text"
															onChange={handleChange}
															value={values.name}
															placeholder="Name *"
															className={
																touched.name && errors.name
																	? "border-danger"
																	: null
															}
														/>
													</div>
													{touched.name && errors.name ? (
														<i className="text-danger">{errors.name}</i>
													) : null}
												</div>
												<div className="col-xs-12 col-md-6">
													<div className="form-group">
														<input
															name="email"
															type="text"
															onChange={onChangeEmail}
															value={values.email}
															placeholder="Email *"
															className={
																touched.email && errors.email
																	? "border-danger"
																	: null
															}
														/>
													</div>
													{touched.email && errors.email ? (
														<i className="text-danger">{errors.email}</i>
													) : null}
												</div>
											</div>

											<div className="row mb-4">
												<div className="col-xs-12 col-md-6 mb-4 mb-sm-0">
													<div className="form-group">
														<input
															name="contactNo"
															type="text"
															onChange={handleChange}
															value={values.contactNo}
															placeholder="Contact Number"
															className={
																touched.contactNo && errors.contactNo
																	? "border-danger"
																	: null
															}
														/>
													</div>
													{touched.contactNo && errors.contactNo ? (
														<i className="text-danger">{errors.contactNo}</i>
													) : null}
												</div>
												<div className="col-xs-12 col-md-6">
													<div className="form-group">
														<input
															name="serviceName"
															type="text"
															onChange={handleChange}
															value={values.serviceName}
															placeholder="Service Name *"
															className={
																touched.serviceName && errors.serviceName
																	? "border-danger"
																	: null
															}
														/>
													</div>
													{touched.serviceName && errors.serviceName ? (
														<i className="text-danger">{errors.serviceName}</i>
													) : null}
												</div>
											</div>

											<div className="row mb-4">
												<div className="col-xs-12">
													<div className="form-group">
														<textarea
															name="message"
															className={
																touched.message && errors.message
																	? "border-danger"
																	: null
															}
															onChange={handleChange}
															value={values.message}
															placeholder="Your Message *"
														/>
													</div>
													{touched.message && errors.message ? (
														<i className="text-danger">{errors.message}</i>
													) : null}
												</div>
											</div>

											<div className="row">
												<div className="col-xs-12">
													<button
														type="submit"
														className="cr-btn"
														disabled={isSubmitting}
													>
														{"Submit"}
													</button>
												</div>
											</div>
										</Form>
									)}
								</Formik>
							</div>
						</div>
					</div>
				</div>
			</section>
		</Fragment>
	);
};

export default Contact;
