import React from "react";
import digitalImg from "../../assets/img/all/digital.jpg";

const AbouArea = (props) => (
	<section className="cr-about-breadcrumb p-relative pt-60 pb-60">
		<div className="container">
			<div className="row">
				<div className="col-lg-6">
					<div
						className="cr-about-breadcrumb-img p-relative text-center"
						data-aos="fade-right"
						data-aos-duration="1500"
					>
						<img src={digitalImg} alt="" className="img-fluid" />
					</div>
				</div>
				<div className="col-lg-6">
					<div
						className="cr-about-3-wrapper"
						data-aos="fade-left"
						data-aos-duration="1500"
					>
						<div className="cr-about-3-title-wrapper">
							<h3 className="cr-section-title">
								{"Best Digital"}
								<span className="title-color">{" Technology"}</span>
								<br />
								{"Agency For People"}
							</h3>
						</div>
						<p className="text">
							Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus
							at porttitor augue. Donec placerat bibendum dictum. Duis egestas
							quam mi, efficitur ornare magna euismod vel. Suspendisse sit amet
							efficitur velit.
						</p>
						<p className="text">
							Sed vel suscipit ligula, sit amet sollicitudin mauris. Quisque non
							semper arcu. In euismod mattis neque, ut condimentum augue
							consectetur a. Integer ultricies elementum urna, quis dictum dui
							venenatis id. Etiam at sollicitudin purus. In fermentum id augue
							sed finibus. Aliquam erat volutpat.
						</p>
					</div>
				</div>
			</div>
		</div>
	</section>
);
export default AbouArea;
