import React, { Fragment, useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";
import BackToTopButton from "../components/BackToTopButton";
import OffCanvasArea from "../components/OffCanvasArea";

const AppLayout = (props) => {
	const location = useLocation();
	useEffect(() => {
		(function ($) {
			$("html, body").animate({ scrollTop: 0 }, 0);
			$(".offcanvas__area").removeClass("offcanvas-opened");
			$(".body-overlay").removeClass("opened");
		})(window.jQuery);
	}, [location]);

	return (
		<Fragment>
			<Header />
			<Outlet />
			<Footer />
			<BackToTopButton />
			<OffCanvasArea />
		</Fragment>
	);
};
export default AppLayout;
