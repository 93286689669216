import React from "react";
import { Link } from "react-router-dom";
import { FiChevronRight } from "react-icons/fi";
import monitoringSvg from "../../assets/img/svg/monitoring.svg";
import memorySvg from "../../assets/img/svg/memory.svg";
import encryptedSvg from "../../assets/img/svg/encrypted.svg";
import backupSvg from "../../assets/img/svg/backup.svg";

const OfferArea = (props) => {
	return (
		<section className="cr-offer-area p-relative pt-70 pb-40">
			<div className="cr-offer-overlay"></div>
			<div className="container">
				<div className="row align-items-center">
					<div className="col-lg-12">
						<div className="cr-feature-title-wrapper">
							<h3 className="cr-section-title">
								{"Services We "}
								<span className="title-color">{"Offer"}</span>
							</h3>
						</div>
					</div>
				</div>
				<div className="row" data-aos="fade-down" data-aos-duration="2000">
					<div className="col-lg-3 col-md-6 col-sm-6">
						<div className="cr-offer-wrapper text-center mb-30">
							<h3 className="offer-title">
								{"Analytic & "}
								<br />
								{"Engineering"}
							</h3>
							<div className="cr-offer-wrapper-thumb">
								<img src={monitoringSvg} alt="" className="img-fluid" />
							</div>
						</div>
					</div>
					<div className="col-lg-3 col-md-6 col-sm-6">
						<div className="cr-offer-wrapper text-center mb-30">
							<h3 className="offer-title">
								{"Machine Learning"}
								<br />
								{"& AI"}
							</h3>
							<div className="cr-offer-wrapper-thumb">
								<img src={memorySvg} alt="" className="img-fluid" />
							</div>
						</div>
					</div>
					<div className="col-lg-3 col-md-6 col-sm-6">
						<div className="cr-offer-wrapper text-center mb-30">
							<h3 className="offer-title">
								{"IT Server & "}
								<br />
								{"Cyber Security"}
							</h3>
							<div className="cr-offer-wrapper-thumb">
								<img src={encryptedSvg} alt="" className="img-fluid" />
							</div>
						</div>
					</div>
					<div className="col-lg-3 col-md-6 col-sm-6">
						<div className="cr-offer-wrapper text-center mb-30">
							<h3 className="offer-title">
								{"Data & Cloud "}
								<br />
								{"Computing"}
							</h3>
							<div className="cr-offer-wrapper-thumb">
								<img src={backupSvg} alt="" className="img-fluid" />
							</div>
						</div>
					</div>
					<div className="col-lg-12">
						<div className="d-flex justify-content-center mt-30 fadeUp">
							<Link className="cr-btn" to="/services">
								{"View More"}
								<FiChevronRight size={20} />
							</Link>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};
export default OfferArea;
