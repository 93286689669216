import React from "react";
import Modal from "react-bootstrap/Modal";

const ServiceThreeModal = (props) => (
	<Modal
		show={props.show}
		onHide={props.onHide}
		backdrop="static"
		keyboard={false}
		dialogClassName="modal-dialog-scrollable modal-xl"
	>
		<Modal.Header closeButton>
			<Modal.Title>{"IT Server & Cyber Security"}</Modal.Title>
		</Modal.Header>
		<Modal.Body>
			<p>
				Ipsum molestiae natus adipisci modi eligendi? Debitis amet quae unde
				commodi aspernatur enim, consectetur. Cumque deleniti temporibus ipsam
				atque a dolores quisquam quisquam adipisci possimus laboriosam.
				Quibusdam facilis doloribus debitis! Sit quasi quod accusamus eos quod.
				Ab quos consequuntur eaque quo rem! Mollitia reiciendis porro quo magni
				incidunt dolore amet atque facilis ipsum deleniti rem!
			</p>
			<p>
				Ipsum molestiae natus adipisci modi eligendi? Debitis amet quae unde
				commodi aspernatur enim, consectetur. Cumque deleniti temporibus ipsam
				atque a dolores quisquam quisquam adipisci possimus laboriosam.
				Quibusdam facilis doloribus debitis! Sit quasi quod accusamus eos quod.
				Ab quos consequuntur eaque quo rem! Mollitia reiciendis porro quo magni
				incidunt dolore amet atque facilis ipsum deleniti rem!
			</p>
			<p>
				Ipsum molestiae natus adipisci modi eligendi? Debitis amet quae unde
				commodi aspernatur enim, consectetur. Cumque deleniti temporibus ipsam
				atque a dolores quisquam quisquam adipisci possimus laboriosam.
				Quibusdam facilis doloribus debitis! Sit quasi quod accusamus eos quod.
				Ab quos consequuntur eaque quo rem! Mollitia reiciendis porro quo magni
				incidunt dolore amet atque facilis ipsum deleniti rem!
			</p>
		</Modal.Body>
	</Modal>
);
export default ServiceThreeModal;
