import React from "react";

const Error404 = () => {
	document.title = "Page Not Found";
	return (
		<div className="row">
			<div className="col-md-12 text-center">
				<h1>404</h1>
				<h5>Page Not Found</h5>
			</div>
		</div>
	);
};

export default Error404;
