import React from "react";
import { Link } from "react-router-dom";
import {
	HOME_PATH,
	ABOUT_US_PATH,
	SERVICES_PATH,
	CONTACT_US_PATH,
} from "../configs/constant";

const Footer = () => (
	<footer className="cr-footer-area p-relative">
		<div className="container-fluid">
			<div className="cr-footer-menu-area p-relative">
				<div className="row align-items-center">
					<div className="col-lg-6 col-md-6">
						<div className="cr-footer-menu">
							<ul>
								<li>
									<Link to={`/${HOME_PATH}`}>{"Home"}</Link>
								</li>
								<li>
									<Link to={`/${ABOUT_US_PATH}`}>{"About Us"}</Link>
								</li>
								<li>
									<Link to={`/${SERVICES_PATH}`}>{"Services"}</Link>
								</li>
								<li>
									<Link to={`/${CONTACT_US_PATH}`}>{"Contact"}</Link>
								</li>
							</ul>
						</div>
					</div>
					<div className="col-lg-6 col-md-6">
						<div className="d-flex justify-content-end align-items-center">
							<small className="m-0 text-white">
								{"© 2024 All right reserved "}
								<strong>{"Graphait Limited"}</strong>
							</small>
						</div>
					</div>
				</div>
			</div>
		</div>
	</footer>
);

export default Footer;
