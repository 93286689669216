import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import AppLayout from "./layout/AppLayout";
import Home from "./pages/Home";
import About from "./pages/About";
import Services from "./pages/Services";
import Contact from "./pages/Contact";
import Error404 from "./pages/Error404";
import {
	HOME_PATH,
	ABOUT_US_PATH,
	SERVICES_PATH,
	CONTACT_US_PATH,
} from "./configs/constant";

const App = () => {
	useEffect(() => {
		(function ($) {
			// Offcanvas
			$(".offcanvas-open-btn").on("click", function () {
				$(".offcanvas__area").addClass("offcanvas-opened");
				$(".body-overlay").addClass("opened");
			});
			$(".offcanvas-close-btn").on("click", function () {
				$(".offcanvas__area").removeClass("offcanvas-opened");
				$(".body-overlay").removeClass("opened");
			});

			// Body overlay
			$(".body-overlay").on("click", function () {
				$(".offcanvas__area").removeClass("offcanvas-opened");
				$(".cartmini__area").removeClass("cartmini-opened");
				$(".body-overlay").removeClass("opened");
			});

			// Sticky Header Js
			$(window).on("scroll", function () {
				const scroll = $(window).scrollTop();
				if (scroll < 100) {
					$("#header-sticky").removeClass("cr-header-sticky");
				} else {
					$("#header-sticky").addClass("cr-header-sticky");
				}
			});

			$("[data-width]").each(function () {
				$(this).css("width", $(this).attr("data-width"));
			});

			$("[data-bg-color]").each(function () {
				$(this).css("background-color", $(this).attr("data-bg-color"));
			});

			// Smooth Scroll Js
			$(".smooth a").on("click", function (event) {
				const target = $(this.getAttribute("href"));
				if (target.length) {
					event.preventDefault();
					$("html, body")
						.stop()
						.animate({ scrollTop: target.offset().top - 120 }, 1500);
				}
			});

			//back to top
			const btn = $("#back_to_top");
			const btn_wrapper = $(".back-to-top-wrapper");

			$(window).scroll(function () {
				if ($(window).scrollTop() > 300) {
					btn_wrapper.addClass("back-to-top-btn-show");
				} else {
					btn_wrapper.removeClass("back-to-top-btn-show");
				}
			});

			btn.on("click", function (e) {
				e.preventDefault();
				$("html, body").animate({ scrollTop: 0 }, "300");
			});

			// scroll rotate
			const reloadClassName = document.getElementById("reload");
			if (reloadClassName !== null) {
				window.onscroll = function () {
					reloadClassName.style.transform =
						"rotate(" + window.scrollY / 2 + "deg)";
				};
			}
		})(window.jQuery);
	}, []);

	return (
		<Routes>
			<Route element={<AppLayout />}>
				<Route exact path="/" element={<Home />} />
				<Route exact path={HOME_PATH} element={<Home />} />
				<Route exact path={ABOUT_US_PATH} element={<About />} />
				<Route exact path={SERVICES_PATH} element={<Services />} />
				<Route exact path={CONTACT_US_PATH} element={<Contact />} />
			</Route>
			<Route path="*" element={<Error404 />} />
		</Routes>
	);
};

export default App;
