import React from "react";
import { Link } from "react-router-dom";

const HeroArea = (props) => {
	return (
		<section className="cr-hero-2-area p-relative">
			<div className="cr-hero-2-wrapper p-relative">
				<div className="container">
					<div className="row align-items-center justify-content-center">
						<div className="col-xl-12">
							<div className="cr-hero-2-content text-center pt-130">
								<div
									className="cr-hero-2-title-wrapper"
									data-aos="fade-up"
									data-aos-duration="1500"
								>
									<h3 className="cr-hero-2-title">
										{"Most Trusted"}
										<svg
											width="258"
											height="5"
											viewBox="0 0 258 5"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M0.466882 4.22307C85.7551 2.55338 170.554 2.288 255.781 4.99709C258.76 5.09661 258.719 2.60867 255.781 2.48703C171.105 -0.962917 84.9592 -1.06243 0.466882 3.70336C-0.145381 3.73653 -0.16579 4.23412 0.466882 4.22307Z"
												fill="currentColor"
											/>
										</svg>
										<br />
										{"Technology"}
										<span>{" Services"}</span>
									</h3>
								</div>
								<div
									className="d-flex justify-content-center"
									data-aos="fade-up"
									data-aos-duration="3000"
								>
									<Link to="/contact" className="cr-btn">
										{"Tell Us How Can We Help"}
									</Link>
								</div>
								{/* <div
									className="cr-hero-2-btn"
									data-aos="fade-up"
									data-aos-duration="3000"
								>
									<Link to="/contact" className="cr-btn">
										{"Tell Us How Can We Help"}
									</Link>
								</div> */}
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default HeroArea;
