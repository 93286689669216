import React, { Fragment, useEffect } from "react";
import Breadcrumb from "../components/Breadcrumb";
import AbouArea from "../components/about/AboutArea";
import SupportArea from "../components/about/SupportArea";

const About = () => {
	document.title = "About";
	useEffect(() => {
		window.AOS.init();
	}, []);

	return (
		<Fragment>
			<Breadcrumb title="About Us" currentPageName="About Us" />
			<AbouArea />
			<SupportArea />
		</Fragment>
	);
};

export default About;
