import React from "react";
import { FiCheck } from "react-icons/fi";
import stayInImg from "../../assets/img/all/stay-in.jpg";

const SupportArea = () => (
	<section className="cr-support-breadcrumb fix pt-80 pb-60">
		<div className="container">
			<div className="row">
				<div className="col-lg-6">
					<div
						className="cr-fun-fact-title-wrapper support-breadcrumb"
						data-aos="fade-right"
						data-aos-duration="1500"
					>
						<h3 className="cr-section-title">
							{"We have been stay "}
							<span className="title-color">{"38 years"}</span>
						</h3>
						<p>
							Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus
							at porttitor augue. Donec placerat bibendum dictum. Duis egestas
							quam mi, efficitur ornare magna euismod vel. Suspendisse sit amet
							efficitur velit.
						</p>
						<ul className="mb-65">
							<li>
								<FiCheck color="#6F7183" size={18} className="me-2" />
								{"Lorem ipsum odor amet, consectetuer adipiscing elit."}
							</li>
							<li>
								<FiCheck color="#6F7183" size={18} className="me-2" />
								{"Lorem ipsum odor amet, consectetuer adipiscing elit."}
							</li>
							<li>
								<FiCheck color="#6F7183" size={18} className="me-2" />
								{"Lorem ipsum odor amet, consectetuer adipiscing elit."}
							</li>
							<li>
								<FiCheck color="#6F7183" size={18} className="me-2" />
								{"Lorem ipsum odor amet, consectetuer adipiscing elit."}
							</li>
							<li>
								<FiCheck color="#6F7183" size={18} className="me-2" />
								{"Lorem ipsum odor amet, consectetuer adipiscing elit."}
							</li>
						</ul>
					</div>
				</div>
				<div className="col-lg-6">
					<div
						className="cr-about-3-img p-relative d-flex justify-content-center align-items-center"
						data-aos="fade-left"
						data-aos-duration="1500"
					>
						<img src={stayInImg} alt="" className="img-fluid" />
					</div>
				</div>
			</div>
		</div>
	</section>
);
export default SupportArea;
