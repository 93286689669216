import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/animate.css";
import "./assets/css/spacing.css";
import "./assets/css/custom.css";
import "./assets/css/style.css";

import App from "./App";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(document.getElementById("root"));
if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
	root.render(
		<BrowserRouter>
			<App />
		</BrowserRouter>
	);
} else {
	root.render(
		<React.StrictMode>
			<BrowserRouter>
				<App />
			</BrowserRouter>
		</React.StrictMode>
	);
}

reportWebVitals();
