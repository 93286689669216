import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { FiMapPin } from "react-icons/fi";
import { FiMail } from "react-icons/fi";
import { FaSquareFacebook } from "react-icons/fa6";
import { FaLinkedin } from "react-icons/fa6";
import { FaBars } from "react-icons/fa6";
import {
	HOME_PATH,
	ABOUT_US_PATH,
	SERVICES_PATH,
	CONTACT_US_PATH,
} from "../configs/constant";

const Header = () => {
	const location = useLocation();
	const [pathname, setPathname] = useState(HOME_PATH);

	useEffect(() => {
		const path = location.pathname.replaceAll("/", "");
		setPathname(path.trim().length === 0 ? HOME_PATH : path);
	}, [location]);

	return (
		<header className="cr-header-area p-relative">
			<div className="cr-header-top cr-header-space d-none d-xl-block">
				<div className="container-fluid">
					<div className="row align-items-center">
						<div className="col-xl-6">
							<div className="cr-header-top-info cr-header-top-right d-flex justify-content-start align-items-center gap-3">
								<div className="header-location d-flex align-items-center me-0">
									<FiMapPin className="icon" size={14} />
									<span>{"Kolkata North East, 745896, West Bengal"}</span>
								</div>
								<div className="header-location d-flex align-items-center">
									<FiMail className="icon" size={16} />
									<span>{"graphait.uk@gmail.com"}</span>
								</div>
							</div>
						</div>
						<div className="col-xl-6">
							<div className="cr-header-top-right d-flex justify-content-end align-items-center">
								<div className="header-social d-flex align-items-center d-md-block d-none">
									<FaSquareFacebook className="icon" size={18} />
									<FaLinkedin className="icon" size={18} />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div
				id="header-sticky"
				className="cr-header-bottom header-sticky p-relative"
			>
				<div className="cr-header-bottom-inner p-relative">
					<div className="container-fluid gx-0">
						<div className="row gx-0 align-items-center">
							<div className="col-xxl-2 col-xl-2 col-lg-10 col-md-6">
								<div className="cr-header-main-left d-flex align-items-center justify-content-xl-center justify-content-xxl-end p-relative">
									<div className="cr-header-logo">
										<Link to={`/${HOME_PATH}`}>
											<h4 className="m-0">LOGO HERE</h4>
										</Link>
									</div>
								</div>
							</div>
							<div className="col-xxl-10 col-xl-10 d-none d-xl-block">
								<div className="cr-main-menu-2-area d-flex justify-content-end align-items-center pe-4">
									<div className="cr-main-menu">
										<nav id="cr-mobile-menu">
											<ul>
												<li>
													<Link
														to={`/${HOME_PATH}`}
														className={pathname === HOME_PATH ? "active" : null}
													>
														{"Home"}
													</Link>
												</li>
												<li>
													<Link
														to={`/${ABOUT_US_PATH}`}
														className={
															pathname === ABOUT_US_PATH ? "active" : null
														}
													>
														{"About Us"}
													</Link>
												</li>
												<li>
													<Link
														to={`/${SERVICES_PATH}`}
														className={
															pathname === SERVICES_PATH ? "active" : null
														}
													>
														{"Services"}
													</Link>
												</li>
												<li>
													<Link
														to={`/${CONTACT_US_PATH}`}
														className={
															pathname === CONTACT_US_PATH ? "active" : null
														}
													>
														{"Contact"}
													</Link>
												</li>
											</ul>
										</nav>
									</div>
								</div>
							</div>
							<div className="col-xxl-4 col-xl-3 col-lg-2 col-md-6 cr-header-mobile-menu">
								<div className="cr-header-mobile-menu d-flex justify-content-end d-block d-md-none">
									<div className="cr-header-hamburger-btn offcanvas-open-btn">
										<button className="hamburger-btn">
											<FaBars className="icon" />
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</header>
	);
};

export default Header;
