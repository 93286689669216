import React, { Fragment, useEffect } from "react";
import HeroArea from "../components/home/HeroArea";
import OfferArea from "../components/home/OfferArea";
import AboutArea from "../components/home/AboutArea";
import HowWeDoArea from "../components/home/HowWeDoArea";

const Home = () => {
	document.title = "Home";
	useEffect(() => {
		window.AOS.init();
	}, []);

	return (
		<Fragment>
			<HeroArea />
			<OfferArea />
			<AboutArea />
			<HowWeDoArea />
		</Fragment>
	);
};

export default Home;
