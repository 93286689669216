import React from "react";
import { Link } from "react-router-dom";
import aboutImg from "../../assets/img/all/about.png";
import headSvg from "../../assets/img/svg/head.svg";
import automationSvg from "../../assets/img/svg/automation.svg";

const AboutArea = (props) => {
	return (
		<section className="cr-business-area p-relative pt-60 pb-60">
			<div className="container">
				<div className="row align-items-center">
					<div className="col-lg-6">
						<div
							className="cr-business-thumb-wrapper p-relative"
							data-aos="fade-right"
							data-aos-duration="1500"
						>
							<div className="cr-business-thumb text-center">
								<img src={aboutImg} alt="" className="img-fluid" />
							</div>
						</div>
					</div>
					<div className="col-lg-6">
						<div className="cr-business-title-wrapper">
							<h3 className="cr-section-title">
								{"Best Digital"}
								<span className="title-color">{" Technology "}</span>
								{"Agency For People"}
							</h3>
							<p>
								Lorem Ipsum is simply dummy text of the printing and typesetting
								industry. Lorem Ipsum has been the industry's standard dummy
								text ever since the 1500s, when an unknown printer took a galley
							</p>
						</div>
						<div className="row">
							<div className="col-lg-6 col-md-6">
								<div className="cr-business-box mb-30">
									<div className="cr-business-box-title d-flex align-items-center">
										<span>
											<img src={headSvg} alt="" className="img-fluid" />
										</span>
										<h4 className="cr-business-title">
											{"Talent as a Service"}
										</h4>
									</div>
									<p>{"Lorem Ipsum has been the industry's when any"}</p>
								</div>
							</div>
							<div className="col-lg-6 col-md-6">
								<div className="cr-business-box mb-30">
									<div className="cr-business-box-title d-flex align-items-center">
										<span>
											<img src={automationSvg} alt="" className="img-fluid" />
										</span>
										<h4 className="cr-business-title">{"Data Intelligence"}</h4>
									</div>
									<p>{"Lorem Ipsum has been the industry's when any"}</p>
								</div>
							</div>
							<div className="cr-business-btn-area d-flex align-items-center mt-20">
								<Link to="/about-us" className="cr-btn">
									{"Our History"}
								</Link>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};
export default AboutArea;
